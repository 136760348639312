import React from "react";
import { InstagramEmbed } from 'react-social-media-embed';
import "./styles.css";

const SocialMediaFeed = () => {
  return (
    <div className="main-root-social-media">
      <h1 className="main-heading-social-media">Our Social Media Feed</h1>
      <div
        className="social-posts-div"
      >
        <InstagramEmbed
          url="https://www.instagram.com/reel/CgUdHFDKYvN/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
          width={400}
          className="facebook-feed-ui"
        />
        <InstagramEmbed
          url="https://www.instagram.com/reel/CgUdHFDKYvN/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
          width={400}
          className="facebook-feed-ui"
        />
      </div>
    </div>
  );
};

export default SocialMediaFeed;
