import React from "react";
import { Outlet } from "react-router-dom";
import FooterStyle3 from "../Footer/FooterStyle3";
import Header from "../Header";
// import { Cursor } from "react-creative-cursor";
// import "react-creative-cursor/dist/styles.css";

export default function Layout3() {
  return (
    <>
      {/* <>
        <Cursor isGelly={true} />
      </> */}
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />
      <Outlet />
      <FooterStyle3 />
    </>
  );
}
