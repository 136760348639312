import React from "react";
import { Dialog } from "@mui/material";
import HeroStyle3 from "../Hero/HeroStyle3";
import Section from "../Section";
import BrandsSection from "../Section/BrandsSection";
import AboutSectionStyle4 from "../Section/AboutSection/AboutSectionStyle4";
import FeaturesSectionStyle4 from "../Section/FeaturesSection/FeaturesSectionStyle4";
import TestimonialSection from "../Section/TestimonialSection";
import BannerSectionStyle8 from "../Section/BannerSection/BannerSectionStyle8";
import TeamSectionStyle4 from "../Section/TeamSection/TeamSectionStyle4";
import FaqSectionStyle3 from "../Section/FaqSection/FaqSectionStyle3";
import DepartmentSection from "../Section/DepartmentSection";
import FeaturesSection from "../Section/FeaturesSection";
import AppointmentModal from "../AppointmentModal/AppointmentModal";
import SocialMediaFeed from "../SocialMediaFeed";
import { pageTitle } from "../../helpers/PageTitle";

const brandData = [
  { imgUrl: "img/aetna.png", imgAlt: "Brand" },
  { imgUrl: "img/blue.png", imgAlt: "Brand" },
  { imgUrl: "img/cigna.png", imgAlt: "Brand" },
  { imgUrl: "img/cms.png", imgAlt: "Brand" },
  { imgUrl: "img/elevance.png", imgAlt: "Brand" },
  { imgUrl: "img/humana.png", imgAlt: "Brand" },
  { imgUrl: "img/united.png", imgAlt: "Brand" },
  { imgUrl: "img/hcsc.png", imgAlt: "Brand" },
];

const featureListData = [
  {
    iconUrl: "/images/home_3/value_icon_1.svg",
    title: "STAGE 1",
    subTitle:
      "<strong>What it means:</strong> Kidney damage with normal kidney function <br /><br /> <strong>Possible signs and symptoms:</strong> High blood pressure, swelling in legs, urinary tract infections, abnormal urine test",
  },
  {
    iconUrl: "/images/home_3/value_icon_2.svg",
    title: "STAGE 2",
    subTitle:
      "<strong>What it means:</strong> Mild loss of kidney function <br /><br /> <strong>Possible signs and symptoms:</strong> High blood pressure, swelling in legs, urinary tract infections, abnormal urine test",
  },
  {
    iconUrl: "/images/home_3/value_icon_3.svg",
    title: "STAGE 3",
    subTitle:
      "<strong>What it means:</strong> Mild to moderate loss of kidney function <br /><br /> <strong>Possible signs and symptoms:</strong> Changes in urination, swelling in hands and feet, weakness or fatigue, dry and itchy skin, back pain, muscle cramping",
  },
  {
    iconUrl: "/images/home_3/value_icon_1.svg",
    title: "STAGE 4",
    subTitle:
      "<strong>What it means:</strong> Severe loss of kidney function <br /><br /> <strong>Possible signs and symptoms:</strong> Anemia, decreased appetite, bone disease, abnormal levels of phosphorus, calcium, vitamin D",
  },
  {
    iconUrl: "/images/home_3/value_icon_2.svg",
    title: "STAGE 5",
    subTitle:
      "<strong>What it means:</strong> Kidney failure or close to failure <br /><br /> <strong>Possible signs and symptoms:</strong> Uremia, fatigue, shortness of breath, nausea, vomiting, abnormal thyroid levels, swelling in hands/legs/eyes/lower back, lower back pain",
  },
];
const featureListData2 = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Acidosis",
    subTitle:
      "We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Chronic Kidney Diseases",
    subTitle:
      "We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Hypertension",
    subTitle: `We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Acute Kidney Failure",
    subTitle:
      "We treat all individuals with respect and dignity, regardless of their background, beliefs, or circumstances. We believe that every person deserves to be treated with compassion and kindness.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Nephritis & Nephropathy",
    subTitle:
      "We believe in working collaboratively with our team membersvand other healthcare professionals to provide comprehensive and effective care to our patients.",
  },
];
const departmentData = [
  {
    title: "Acidosis",
    iconUrl: "/images/home_3/department_icon_1.svg",
    href: "/departments/department-details",
  },
  {
    title: "Hypertension",
    iconUrl: "/images/home_3/department_icon_2.svg",
    href: "/departments/department-details",
  },
  {
    title: "Chronic Kidney Diseases",
    iconUrl: "/images/home_3/department_icon_3.svg",
    href: "/departments/department-details",
  },
  {
    title: "Acute Kidney Failure",
    iconUrl: "/images/home_3/department_icon_4.svg",
    href: "/departments/department-details",
  },
  {
    title: "Nephritis & Nephropathy",
    iconUrl: "/images/home_3/department_icon_5.svg",
    href: "/departments/department-details",
  },
];
const testimonialData = [
  {
    text: "I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_3/testimonial_img_1.png",
    avatarName: "LAURENCE VENDETTA",
  },
  {
    text: "I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.",
    ratingNumber: "4.5",
    avatarImgUrl: "/images/home_3/testimonial_img_2.png",
    avatarName: "PAULO HUBERT",
  },
  {
    text: "I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.",
    ratingNumber: "5",
    avatarImgUrl: "/images/home_3/testimonial_img_1.png",
    avatarName: "LAURENCE VENDETTA",
  },
  {
    text: "I recently had to bring my child to ProHealth for a minor injury, and I was so impressed with the care he received. The pediatrician was great with him and made him feel at ease, and the entire staff was kind and attentive.",
    ratingNumber: "4.5",
    avatarImgUrl: "/images/home_3/testimonial_img_2.png",
    avatarName: "PAULO HUBERT",
  },
];
const teamData = [
  {
    imgUrl: "/img/hudaArif.jpeg",
    name: "Dr. Huda Arif, MD",
    designation: "Nephrology",
    link: "/doctors/doctor-details",
    description:
      "With expertise in managing complex heart conditions and performing advanced cardiac procedures",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/home-v3" },
      { icon: "fa6-brands:linkedin-in", href: "/home-v3" },
      { icon: "fa6-brands:twitter", href: "/home-v3" },
    ],
  },
  {
    imgUrl: "/img/hudaArif.jpeg",
    name: "Dr. Usman Ahmed, MD",
    designation: "Emergency Medicine Physician",
    link: "/doctors/doctor-details",
    description:
      "He is a highly experienced emergency medicine physician with over 15 years of experience.",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/home-v3" },
      { icon: "fa6-brands:linkedin-in", href: "/home-v3" },
      { icon: "fa6-brands:twitter", href: "/home-v3" },
    ],
  },
];
const faqData = [
  {
    title: "What are kidney diseases?",
    content:
      "Kidney diseases are conditions that affect the kidneys, impairing their function to filter waste and excess fluids from the blood to make urine.",
  },
  {
    title: "What causes kidney diseases?",
    content:
      "Kidney diseases can be caused by various factors, including diabetes, high blood pressure, certain medications, infections, autoimmune disorders, genetic factors, and other health conditions.",
  },
  {
    title: "What are the symptoms of kidney diseases?",
    content:
      "Symptoms can vary but may include fatigue, swelling in the ankles, feet, or hands, foamy or bloody urine, increased or decreased urine output, difficulty concentrating, and more.",
  },
  {
    title: "How are kidney diseases diagnosed?",
    content:
      "Diagnosis often involves blood tests to measure kidney function, urine tests to check for abnormalities, imaging tests like ultrasound or CT scans, and kidney biopsy in some cases.",
  },
  {
    title: "Can kidney diseases be prevented?",
    content:
      "Some kidney diseases can be prevented by maintaining a healthy lifestyle, controlling blood pressure and blood sugar levels, avoiding excessive use of over-the-counter pain medications, and staying hydrated.",
  },
];
const blogData = [
  {
    title: "The Benefits of Mindfulness Meditation for Stress and Anxiety",
    thumbUrl: "/images/home_3/blog_1.jpeg",
    date: "May 21, 2023",
    href: "/blog/blog-details",
    socialShare: true,
  },
  {
    title:
      "Understanding Chronic Fatigue Syndrome: Symptoms, Causes, and Treatment",
    thumbUrl: "/images/home_3/blog_2.jpeg",
    date: "May 20, 2023",
    href: "/blog/blog-details",
    socialShare: false,
  },
  {
    title: "The Importance of Regular Cancer Screenings and Early Detection",
    thumbUrl: "/images/home_3/blog_3.jpeg",
    date: "May 15, 2023",
    href: "/blog/blog-details",
    socialShare: false,
  },
  {
    title:
      "Managing Chronic Pain: Tips and Strategies for a Better Quality of Life",
    thumbUrl: "/images/home_3/blog_4.jpeg",
    date: "May 10, 2023",
    href: "/blog/blog-details",
    socialShare: false,
  },
];
export default function HomeStyle3() {
  pageTitle("Home");
  const [show, setShow] = React.useState(false);
  const [bookingForm, setBookingForm] = React.useState(false);
  const [appModal, setAppModal] = React.useState(false);

  const bookingFormShow = () => {
    setBookingForm(true);
  };

  const bookingFormHide = () => {
    setBookingForm(false);
  };

  const handleModalOpen = () => {
    setBookingForm(false);
    setShow(true);
  };

  const handleModalClose = () => {
    setShow(false);
    setAppModal(false);
  };

  const handleAppointmentModal = () => {
    setShow(false);
    setAppModal(true);
  };

  return (
    <>
      <HeroStyle3
        title="Making Health Easy and Accessible"
        subTitle="At Kidney Care Associates, we prioritize our patient's health and well-being, and
        we strive to provide compassionate care with exceptional results."
        bgUrl="/images/home_3/hero_bg.svg"
        imgUrl="/img/kidney.png"
        imgUrl2="/images/home_3/hero_img_2.png"
        imgUrl3="/images/home_3/hero_img_3.png"
        imgUrl4="/images/home_3/hero_img_4.png"
        avatarName="Kidney Care Associates"
        handleBookingForm={bookingFormShow}
        avatarSheduleList={[
          {
            icon: "fa6-solid:calendar-days",
            title: "Days :",
            details: "Monday - Thursday",
          },
          {
            icon: "fa6-regular:clock",
            title: "Time :",
            details: "09.00 Am - 07.00 PM",
          },
        ]}
      />
      <Section>
        {/* <AppointmentSectionStyle3 title="Book Appoinment Now" /> */}
        <FeaturesSection
          sectionTitle="Our Services"
          data={featureListData2}
          handleBookingForm={bookingFormShow}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} sectionTitle="Our Insurance Partners" />
      </Section>
      <Section>
        <AboutSectionStyle4
          imgUrl="/img/aboutH.jpg"
          // spiningImgUrl="/images/home_1/about_mini.svg"
          titleUp="ABOUT US"
          title="Kidney Care is a team of experienced medical professionals"
          handleBookingForm={bookingFormShow}
          subTitle="Dedicated to providing top-quality healthcare services. We believe in a <br /> holistic approach to healthcare that focuses on treating the whole <br />person, not just the illness or symptoms."
        />
      </Section>
      <Section topMd={195} topLg={145} topXl={105}>
        <TeamSectionStyle4
          sectionTitle="Expert Doctors"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={200} topLg={150} topXl={110}>
        <FeaturesSectionStyle4
          sectionTitle="Stages Of Chronic Kidney Disease"
          data={featureListData}
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={175}
        bottomLg={125}
        bottomXl={85}
      >
        <DepartmentSection
          sectionTitle="Our Services"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
          handleOpen={bookingFormShow}
        />
        {bookingForm && (
          <Dialog open={bookingForm} onClose={bookingFormHide} sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",
                padding: "25px 60px",
              },
            },
          }}>
            <div
              className="cs_appointment_form_fields">
              <div className="cs_appointment_form_field">
                {/* <div className="cs_appointment_form_icon cs_center rounded-circle cs_accent_bg">
                  <img src="/images/home_3/appointment_icon_1.svg" alt="Icon" />
                </div> */}
                <div
                  className="cs_appointment_form_field_right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                  }}
                >
                  <label>Name</label>
                  <input type="text" placeholder="David John" />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                {/* <div className="cs_appointment_form_icon cs_center rounded-circle cs_accent_bg">
                  <img src="/images/home_3/appointment_icon_2.svg" alt="Icon" />
                </div> */}
                <div
                  className="cs_appointment_form_field_right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                  }}
                >
                  <label>Phone Number</label>
                  <input type="text" placeholder="(123) 456 - 789" />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                {/* <div className="cs_appointment_form_icon cs_center rounded-circle cs_accent_bg">
                  <img src="/images/home_3/appointment_icon_3.svg" alt="Icon" />
                </div> */}
                <div
                  className="cs_appointment_form_field_right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                  }}
                >
                  <label>Medical Record Number</label>
                  <input type="text" placeholder="123456-7890-0987" />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                {/* <div className="cs_appointment_form_icon cs_center rounded-circle cs_accent_bg">
                  <img src="/images/home_3/appointment_icon_4.svg" alt="Icon" />
                </div> */}
                <div
                  className="cs_appointment_form_field_right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                  }}
                >
                  <label>Reason for Visit</label>
                  <input type="text" placeholder="Routine Checkup" />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                {/* <div className="cs_appointment_form_icon cs_center rounded-circle cs_accent_bg">
                  <img src="/images/home_3/appointment_icon_5.svg" alt="Icon" />
                </div> */}
                <div
                  className="cs_appointment_form_field_right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                  }}
                >
                  <label>Preferred Date</label>
                  <input
                    type="text"
                    placeholder="August 23, 2023"
                    id="datepicker"
                  />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                {/* <div className="cs_appointment_form_icon cs_center rounded-circle cs_accent_bg">
                  <img src="/images/home_3/appointment_icon_6.svg" alt="Icon" />
                </div> */}
                <div
                  className="cs_appointment_form_field_right"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "15px",
                  }}
                >
                  <label>Preferred Time</label>
                  <input
                    type="text"
                    className="cs_timepicker"
                    placeholder="10:00AM"
                  />
                </div>
              </div>
              <div className="d-lg-block">
                <button
                  className="cs_btn cs_style_1"
                  onClick={() => handleModalOpen()}
                  type="button"
                >
                  <span>Book Now</span>
                  <i>
                    <img src="/images/icons/arrow_white.svg" alt="Icon" />
                    <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  </i>
                </button>
              </div>
            </div>
          </Dialog>
        )}
        {show && (
          <AppointmentModal handleOpen={show} handleClose={handleModalClose} />
        )}
      </Section>
      <Section
        className="cs_bg_filed"
        style={{ backgroundImage: "url(/images/home_3/testimonial_bg.svg)" }}
      >
        {/* <Section
          topMd={190}
          topLg={145}
          topXl={105}
          bottomMd={200}
          bottomLg={150}
          bottomXl={110}
        >
          <TestimonialSectionStyle3
            sectionTitle="What Our Patients Say <br />About Us"
            sectionTitleUp="TESTIMONIALS"
            data={testimonialData}
          />
        </Section> */}
        <Section
          topMd={185}
          topLg={140}
          topXl={100}
          bottomMd={200}
          bottomLg={150}
          bottomXl={110}
        >
          <TestimonialSection
            sectionTitle="Some Reviews"
            sectionTitleDown="Of our clients"
          />
        </Section>
        <BannerSectionStyle8
          imgUrl="/img/backseat.jpg"
          bgUrl="/images/home_3/banner_bg.svg"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section>
      {/* <Section topMd={160} topLg={120} topXl={70}>
        <BlogSectionStyle4
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      <Section>
        <SocialMediaFeed />
      </Section>
      <Section topMd={190} topLg={55} topXl={105}>
        <FaqSectionStyle3
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
          sectionTitleDown=""
          sectionSubTitle=""
        />
      </Section>
    </>
  );
}
