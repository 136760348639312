import { useEffect } from 'react';
import { CaretDownFilled } from '@ant-design/icons';

const InsuranceInput = ({
    insuranceRef,
    insuranceToggle,
    setInsuranceToggle,
    insurance,
    setInsurance,
    insurancePhrase,
    setInsurancePhrase,
    handleInsuranceChange,
    setInsurancePlan,
    insurancePlan
}) => {
    const insuranceOptions = [
        {
            name: "AETNA",
            plans: ["Aetna HMO", "Choice Plus POS II", "Elect Choice (Open Access) EPO", "Managed Choice (Open Access) POS", "Open Choice PPO", "Premier Care Network Plus - Innovation Health", "Signature Administrators PPO"]
        },
        {
            name: "AMBETTER",
            plans: ["Superior Health Plan"]
        },
        {
            name: "BLUE CROSS AND BLUE SHIELD OF TEXAS",
            plans: ["BestChoice PPO", "Blue Advantage HMO", "Blue Essentials HMO"]
        },
        {
            name: "BLUE CROSS BLUE SHIELD",
            plans: ["Blue Card High Performance Network HPN", "Blue Card PPO"]
        },
        {
            name: "CIGNA",
            plans: ["Cigna PPO", "Open Access Plus"]
        },
        {
            name: "UNITEDHEALTHCARE",
            plans: ["Choice Plus POS", "Navigate HMO", "Navigate POS", "Nexus Open Access", "Options PPO"]
        },
    ];

    const filteredInsurance = insuranceOptions.filter(ins => ins.name.toLowerCase().startsWith(insurancePhrase.toLowerCase()));

    const selectInsurance = (selectedInsurance) => {
        setInsurance(selectedInsurance);

        if (insurance === selectedInsurance) {
            setInsurancePlan('');
            setInsurance('')
        }
    };

    const selectInsurancePlan = (plan) => {
        setInsurancePlan(plan);
        setInsuranceToggle(false);
    };

    const handleOutsideClick = (e) => {
        if (insuranceRef.current && !insuranceRef.current.contains(e.target)) {
            setInsuranceToggle(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
            setInsurancePhrase('');
        };
    }, []);

    return (
        <div className="relative w-[253px] 2xl:w-[350px] 5xl:w-[443px]" ref={insuranceRef}>
            <input
                type="text"
                autoComplete="off"
                name="insurance"
                id="insurance"
                value={`${!insuranceToggle ? insurancePlan !== "" ? `${insurance} - ${insurancePlan}` : "" : insurancePhrase}`}
                placeholder="Insurance carrier and plan"
                className="select-custom p-2 px-2 border border-[#E2E4E5] rounded-sm w-[253px] 2xl:w-[350px] 5xl:w-[443px] outline-none hover:border-[#10111a7e] font-medium text-sm duration-200 cursor-pointer text-[#687385]"
                onChange={(e) => {
                    handleInsuranceChange(e);
                    setInsuranceToggle(true);
                }}
                onClick={() => setInsuranceToggle(true)}
            />

            {insuranceToggle && (
                <div className="absolute top-10 right-0 w-full bg-white rounded-lg shadow-lg border border-gray-100 text-left">
                    <p className="font-medium my-3 px-5">Insurance Accepted</p>
                    <div className="flex flex-col">
                        {filteredInsurance.map((option, index) => (
                            <div key={index}>
                                <span className="font-normal hover:bg-blue-100 duration-100 px-5 py-2 cursor-pointer flex items-center gap-5 justify-between"
                                    onClick={() => selectInsurance(option.name)}><span>{option.name}</span> <span className={`duration-500 ${option.name === insurance ? "rotate-180" : "rotate-0"}`}><CaretDownFilled /></span></span>

                                {insurance === option.name && (
                                    <div className="ml-5 ddl-option">
                                        {option.plans.map((plan, planIndex) => (
                                            <span key={planIndex}
                                                className={`block font-normal hover:bg-blue-100 duration-100 px-5 py-2 cursor-pointer ${insurancePlan === plan ? "bg-blue-100" : ""}`}
                                                onClick={() => { selectInsurancePlan(plan); setInsurancePhrase("") }}>{plan}</span>
                                        ))}
                                    </div>
                                )}

                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default InsuranceInput;
