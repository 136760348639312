import React, { useEffect, useRef, useState } from "react";
import InsuranceInput from "./InsuranceInput";
import { Dialog } from "@mui/material";
import { FormatSlot } from "../../services/utils/FormatSlots";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./AppointmentModal.scss";

const AppointmentModal = ({
  setAppointmentModal,
  appointmentModal,
  physicianID,
  physicianDetail,
  form,
  setForm,
  initialState,
  setSelectedOption,
  selectedOption,
  selectedDateFromUrl,
  messageApi,
  handleOpen,
  handleClose,
}) => {
  const [slotLoading, setSlotLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [day, setDay] = useState("");
  const [error, setError] = useState("");

  const [patientReq, setPatientReq] = useState({
    selected_date: selectedDateFromUrl || "",
    meeting_type: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    reason: "",
    address: "N/A",
    city: "",
    date_of_birth: "",
    zip_code: "",
    // 'physician_id': physicianID,
    // showGuardianNotification: false
  });

  const fetchSlots = async (id, selected_date) => {
    setError("");
    const currentDate = new Date();
    const selectedDay =
      selected_date !== undefined ? new Date(selected_date).getDay() : null; // 0 for Sunday, 1 for Monday
    const isCurrentDate =
      selected_date &&
      new Date(selected_date).toDateString() === currentDate.toDateString();

    // Prevent selection of Sundays
    if (selectedDay === 0) {
      // toast.error("Physician is not available on Sundays");

      messageApi.open({
        type: "error",
        content: "Physician is not available on Sundays",
      });
      return;
    }

    setSlotLoading(true);
    try {
      // https://preferredmed.longliveik.com/api
      // http://127.0.0.1:8000/api
      const response = await axios.post(
        `https://preferredmed.longliveik.com/api/get-available-slots/${id}`,
        {
          physician_id: id,
          selected_date: selected_date,
        }
      );

      if (response.data?.status && response.data.status === 400) {
        setError(response.data.message);
        setSlots([]);
        setSlotLoading(false);
        return;
      }

      let availableSlots = response.data || [];
      if (isCurrentDate) {
        // Get current time in HH:MM format
        const currentTime =
          currentDate.getHours().toString().padStart(2, "0") +
          ":" +
          currentDate.getMinutes().toString().padStart(2, "0");

        // Filter out slots that have already passed
        availableSlots = availableSlots.filter((slot) => slot > currentTime);
      }

      setSlots(availableSlots);
    } catch (error) {
      console.log(error);
      // toast.error("Error fetching slots");
    }
    setSlotLoading(false);
  };

  const handleChangeDate = (e) => {
    fetchSlots(
      physicianID,
      e.target.name === "selected_date" && e.target.value
    );
  };

  const handleChange = (e) => {
    if (e.target.name === "selected_date") {
      const selectedDate = e.target.value;
      const selectedDay = new Date(selectedDate).getDay();

      // Prevent selection of Sundays
      if (selectedDay === 0) {
        return;
      } else {
        setPatientReq({
          ...patientReq,
          [e.target.name]: selectedDate,
        });

        // Day name for the selected date
        setDay(
          new Date(selectedDate).toLocaleDateString("en-US", {
            weekday: "long",
          })
        );
      }
    } else {
      setPatientReq({
        ...patientReq,
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "date_of_birth") {
        // Validate date of birth to check if patient is under 18
        const currentDate = new Date();
        const selectedDate = new Date(e.target.value);
        const ageDifference =
          currentDate.getFullYear() - selectedDate.getFullYear();

        if (ageDifference < 18) {
          // If patient is under 18, show notification
          setPatientReq((prevState) => ({
            ...prevState,
            showGuardianNotification: true,
          }));
        } else {
          // If patient is 18 or older, hide notification
          setPatientReq((prevState) => ({
            ...prevState,
            showGuardianNotification: false,
          }));
        }
      }
    }
  };

  const getCurrentDate = () => {
    let today = new Date();
    const dayOfWeek = today.getDay(); // Sunday is 0, Monday is 1, etc.

    // If today is Sunday (dayOfWeek === 0), advance the date to the next day
    if (dayOfWeek === 0) {
      today.setDate(today.getDate() + 1);
    }

    let year = today.getFullYear();
    let month = today.getMonth() + 1; // January is 0
    let day = today.getDate();

    // Add leading zeros if month/day is less than 10
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  };

  const [AppointmentCount, setAppointmentCount] = useState(1);
  // const submitAppointment = async () => {

  //     if (patientReq.meeting_type === "") {
  //         toast.error("Please select a meeting type");
  //         return;
  //     }

  //     await toast.promise(
  //         makeAppointment(),
  //         {
  //             loading: 'Submitting...',
  //             success: `5 Appointment made successfully`,
  //             error: 'Something went wrong please try again later',
  //         }
  //     );
  // };

  const makeAppointment = async () => {
    if (patientReq.meeting_type === "") {
      // toast.error("Please select a meeting type");
      messageApi.open({
        type: "error",
        content: "Please select a meeting type",
      });
      return;
    }

    try {
      // https://preferredmed.longliveik.com/api
      // http://127.0.0.1:8000/api
      const response = await axios.post(
        "https://preferredmed.longliveik.com/api/create-appointment",
        { ...patientReq, selectedSlot }
      );
      if (response.data.appointmentStatus === 200) {
        setAppointmentCount(response.data.totalAppointments);
        setAppointmentModal({ isOpen: false, physicianID: "" });
        setForm(initialState);
        setSelectedOption("");

        messageApi.open({
          type: "success",
          content:
            "Appoinment made successfully. Please check your email for confirmation.",
        });
      } else if (response.data.appointmentStatus === 500) {
        console.log(response.data.message);

        messageApi.open({
          type: "error",
          content: "Appoinment failed.",
        });

        return;
      }
    } catch (error) {
      // return Promise.reject();

      messageApi.open({
        type: "error",
        content: "Appoinment failed.",
      });

      console.log(error);
    }
  };

  const [reviewAndBook, setReviewAndBook] = useState(false);

  const [newPatient, setNewPatient] = useState("");

  const handleBookAndReview = () => {
    // if (
    //   patientReq.first_name === "" ||
    //   patientReq.last_name === "" ||
    //   patientReq.email === "" ||
    //   patientReq.phone === "" ||
    //   patientReq.address === "" ||
    //   patientReq.date_of_birth === "" ||
    //   patientReq.reason === ""
    // ) {
    //   // toast.error("Please fill all the fields");
    //   // messageApi.open({
    //   //   type: "error",
    //   //   content: "Please fill all the fields",
    //   // });
    //   setReviewAndBook(false);
    //   return;
    // }

    setReviewAndBook(true);
  };

  const insuranceRef = useRef(null);
  const [insuranceToggle, setInsuranceToggle] = useState("");

  useEffect(() => {
    if (insuranceRef.current) {
      insuranceRef.current.addEventListener("click", () => {
        setInsuranceToggle(true);
      });

      document.addEventListener("click", (e) => {
        if (insuranceRef.current && !insuranceRef.current.contains(e.target)) {
          setInsuranceToggle(false);
        }
      });
    }
  }, [reviewAndBook]);

  const [insurance, setInsurance] = useState("");
  const [insurancePlan, setInsurancePlan] = useState("");
  const [insurancePhrase, setInsurancePhrase] = useState("");
  const handleInsuranceChange = (e) => {
    setInsurancePhrase(e.target.value);
  };

  const [value, setValue] = useState("");
  const [errorMemberID, setErrorMemberID] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);

    if (value.length < 8 || value.length > 12) {
      setErrorMemberID("Number of digits should be between 8 and 12.");
    } else {
      setErrorMemberID("");
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  // number of units
  const [units, setUnits] = useState(1);
  const handleUnit = (id, value) => {
    // setUnits(value);
    setSelectedOption((prevState) => {
      return prevState.map((option) => {
        if (option.id === id) {
          return {
            ...option,
            units: value,
            NumberPrice: option.NumberPrice * value,
          };
        }
        return option;
      });
    });
  };

  const [coupon, setCoupon] = useState("");
  const [addedCoupon, setAddedCoupon] = useState({
    coupon: "",
    discountMsg: "20% discount applied",
  });

  const handleCoupon = (e) => {
    setCoupon(e.target.value);
  };

  const addCoupon = () => {
    if (coupon === "") {
      // toast.error("Please enter a coupon code");

      messageApi.open({
        type: "error",
        content: "Please enter a coupon code",
      });
      return;
    }

    if (coupon.toLowerCase() === "b102") {
      setAddedCoupon({ coupon: coupon, discountMsg: "20% discount applied" });
    } else {
      // toast.error("Invalid coupon code");

      messageApi.open({
        type: "error",
        content: "Invalid coupon code",
      });
    }
  };

  const PriceFilter = (price) => {
    let totalPrice = price;

    if (addedCoupon.coupon.toLowerCase() === "b102") {
      totalPrice = totalPrice - totalPrice * 0.2;
    }

    return totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const PriceFormat = (price) => {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    fetchSlots(physicianID);
  }, [physicianID]);

  const [orderToggle, setOrderToggle] = useState({
    check: false,
    isOpen: false,
  });

  const [totalPrice, setTotalPrice] = useState(0);

  // useEffect(() => {
  //     selectedOption.length > 0 && selectedOption.map((option) => {
  //         setTotalPrice(prevState => prevState + option.NumberPrice);
  //     })

  // }, [selectedOption]);

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setAppointmentModal({ isOpen: false, physicianID: "" });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.id);
  };

  return (
    <Dialog open={handleOpen} onClose={handleClose} keepMounted>
      <div style={{ padding: "20px 0" }}>
        <div>
          <div className="bg-white">
            <div style={{ padding: "10px 30px" }}>
              <div
                className="flex items-start justify-between gap-5"
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div>
                  <img
                    src="/img/hudaArif.jpeg"
                    alt="Doctor Image"
                    className="rounded-full w-[60px] object-contain"
                    width={100}
                    height={100}
                  />
                </div>
                <div className="flex flex-col">
                  <h1
                    className="font-medium"
                    style={{ fontSize: "24px", margin: "0" }}
                  >
                    Dr.Huda Arif
                  </h1>
                  <p className="text-sm text-gray-800" style={{ margin: "0" }}>
                    Speciality: Nephrology
                  </p>
                  <p className="text-sm" style={{ margin: "0" }}>
                    Location: Fort Worth, TX
                  </p>
                  <p className="mt-2">
                    {selectedSlot === null || selectedSlot === ""
                      ? ""
                      : "Slot: " + FormatSlot(selectedSlot) + " " + day}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* 
                    <div className="bg-white h-[fit-content] max-h-[100vh] w-full sm:w-[600px] rounded-lg shadow-md overflow-y-auto mb-2">
                        <div className='p-5'>

                            <div className='flex flex-col gap-2'>
                                <div className='flex items-center gap-5 justify-between'>
                                    <h1 className='font-[600] text-xs sm:text-lg flex items-center gap-2'><CheckCircleOutlined color='#2A62A4' size={20} /> Order Summary</h1>

                                    <h1 className='font-[600] text-xs sm:text-lg flex items-center gap-2'><ShoppingFilled color='#2A62A4' size={20} />
                                        Total: ${PriceFilter(totalPrice)}
                                    </h1>
                                </div>

                                <hr />


                                {selectedOption.map((option, index) => (
                                    <div key={index} className='flex items-center gap-3 justify-between py-2'>
                                        <div className='flex-1 flex items-start gap-3'>
                                            <img
                                                src={option.title_image}
                                                alt='Service Image'
                                                className='w-[50px] h-[50px] object-contain rounded-full'
                                                width={100}
                                                height={100}
                                            />
                                            <div>
                                                <h1 className='font-medium'>{option.name}</h1>
                                                <p className='text-sm text-gray-500'>{option.description}</p>
                                                <span className='text-sm'>{option.price}</span>
                                            </div>
                                        </div>

                                        <div className='flex flex-col gap-1'>
                                            <label htmlFor="unit" className='text-sm'>Units:</label>
                                            <input type="number" name='unit' min={1} className='border border-gray-300 rounded-md px-2 p-2 w-[50px]' placeholder='0' value={option?.units || 1} onChange={(e) => handleUnit(option.id, e.target.value)} />
                                        </div>
                                        <span className='rounded-md px-2 p-2 font-medium block text-sm sm:text-base'>${PriceFormat(option.NumberPrice)}</span>
                                    </div>
                                ))}


                                <div className='flex-1 flex flex-col gap-1 items-end'>
                                    <label htmlFor="coupon">Apply Coupon</label>
                                    <div className='flex items-center gap-1'>
                                        <input type="text" placeholder='Coupon Code' value={coupon} onChange={handleCoupon} className='border border-gray-300 rounded-md px-2 p-2' />
                                        <button className='px-3 py-2 bg-[#2A62A4] hover:bg-buttonHover hover:shadow-xl rounded-md text-white duration-300 text-[14px] md:text-[16px]' onClick={addCoupon}>Add</button>
                                    </div>
                                    {addedCoupon.coupon !== "" && <span className='text-xs text-[#2A62A4]'>{addedCoupon.discountMsg}</span>}
                                </div>

                            </div>
                        </div>
                    </div> */}

          <div className="bg-white">
            <div style={{ padding: "0 30px 40px" }}>
              <div className="flex items-center justify-between">
                {!orderToggle.isOpen ? (
                  reviewAndBook ? (
                    <h1
                      className="font-[600]"
                      style={{ fontSize: "20px", margin: "0" }}
                    >
                      Review and Book
                    </h1>
                  ) : (
                    <h1
                      className="font-[600]"
                      style={{ fontSize: "20px", margin: "0" }}
                    >
                      Appointment In Progress
                    </h1>
                  )
                ) : (
                  <h1
                    className="font-[600]"
                    style={{ fontSize: "20px", margin: "0" }}
                  >
                    Pay and Book
                  </h1>
                )}

                {/* select option for meeting type */}
                {!reviewAndBook && (
                  <div className="flex mb-2">
                    <select
                      name="meeting_type"
                      id="meeting_type"
                      className="border border-gray-300 rounded-md py-2 px-2 outline-none"
                      defaultValue={""}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="" disabled>
                        select meeting type
                      </option>
                      <option value="in_person">In Person</option>
                      <option value="virtual">Virtual</option>
                    </select>
                  </div>
                )}
              </div>

              <hr />

              {!orderToggle.isOpen ? (
                !reviewAndBook ? (
                  selectedSlot === null ? (
                    <div>
                      <div className="flex flex-wrap-reverse gap-5 items-center justify-between py-2">
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor="date"
                            className="font-[400]  text-[12px] md:text-[14px]"
                          >
                            Date:
                          </label>
                          <div></div>
                          <input
                            type="date"
                            id="date"
                            name="selected_date"
                            className="border border-gray-300 rounded-md px-2 md:p-2"
                            min={getCurrentDate()}
                            value={getCurrentDate()}
                            onChange={(e) => {
                              handleChangeDate(e), handleChange(e);
                            }}
                          />
                        </div>

                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor="time"
                            className="font-[400] text-[12px] md:text-[14px]"
                          >
                            Physician Name:
                          </label>
                          <h1
                            className="text-[#2A62A4]"
                            style={{ fontSize: "18px" }}
                          >
                            Dr. Huda Arif
                          </h1>
                        </div>
                      </div>
                      {patientReq.selected_date === "" ? (
                        <p className="text-[16px] text-center py-5">
                          Please select a date
                        </p>
                      ) : (
                        <div className="py-1" style={{ textAlign: "center" }}>
                          <p className="text-[12px] pb-2">
                            Available time slots
                            <span className="text-[#FF0000] text-[10px]">
                              &nbsp;(Appointment will be of 30 minutes)
                            </span>
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "15px",
                              justifyContent: "center",
                            }}
                          >
                            {slotLoading ? (
                              <div
                                style={{
                                  height: "50vh",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{ fontSize: 24 }}
                                      spin
                                    />
                                  }
                                />
                              </div>
                            ) : slots.length > 0 ? (
                              slots?.map((slot, index) => (
                                <button
                                  key={index}
                                  className={`btn-time ${
                                    selectedSlot === slot
                                      ? "bg-[#27bf74] hover:bg-[#27bf73eb]"
                                      : "bg-[#2A62A4] hover:bg-buttonHover"
                                  }`}
                                  style={{
                                    background: "#2A62A4",
                                    color: "#fff",
                                  }}
                                  onClick={() => {
                                    setSelectedSlot(slot);
                                  }}
                                >
                                  <span className="block">
                                    {FormatSlot(slot)}
                                  </span>
                                  <span className="block text-xs">{day}</span>
                                </button>
                              ))
                            ) : error === "" ? (
                              <p className="text-[12px] mx-auto">
                                No slots available today
                              </p>
                            ) : (
                              <p className="text-[12px] text-red-500 mx-auto">
                                {error}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="border-t pt-2 flex items-center gap-5 justify-end">
                        <button
                          className="btn btn-default px-3 py-2 hover:shadow-xl border border-gray-300 rounded-md text-black duration-300 text-[14px] md:text-[16px]"
                          // onClick={() => {
                          //   setAppointmentModal({
                          //     isOpen: false,
                          //     physicianID: "",
                          //   });
                          // }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <form onSubmit={makeAppointment}>
                      <div
                        className="flex items-start justify-between gap-2 flex-wrap py-3"
                        style={{ paddingBottom: "10px" }}
                      >
                        {/* <div className='flex flex-col gap-2'>
                                            <label htmlFor="first_name" className='font-[400] text-[14px]'>Physician Name</label>
                                            <h1 className='text-[#2A62A4]'>{physicianDetail.first_name} {physicianDetail.last_name}</h1>
                                        </div> */}

                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor="time"
                            className="font-[400] text-[14px]"
                          >
                            Your Slot
                          </label>
                          <h1
                            className="text-[#2A62A4]"
                            style={{ fontSize: "26px" }}
                          >
                            {FormatSlot(selectedSlot)}
                          </h1>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "50px",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label
                            htmlFor="facility"
                            className="font-[400] text-[14px]"
                          >
                            Facility
                          </label>
                          <input
                            type="text"
                            id="facility"
                            name="facility"
                            placeholder="facility"
                            className="border border-gray-300 rounded-md p-2"
                            // value={patientReq.address}
                            // onChange={(e) => { handleChange(e) }}
                            required
                          />
                        </div>

                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <label
                            htmlFor="date_of_birth"
                            className="font-[400] text-[14px]"
                          >
                            Date of birth
                          </label>
                          <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            placeholder="Date of birth"
                            className="border border-gray-300 rounded-md p-2"
                            value={patientReq.date_of_birth}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            required
                          />
                          {patientReq.showGuardianNotification && (
                            <p className="text-red-500 text-xs w-[150px]">
                              Patients under 18 should be accompanied by a
                              guardian.
                            </p>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "10px",
                        }}
                      >
                        <label
                          htmlFor="reason"
                          className="font-[400] text-[14px]"
                        >
                          Additional information for Doctor
                        </label>
                        <textarea
                          id="reason"
                          name="reason"
                          placeholder="Reason for appointment"
                          className="border border-gray-300 rounded-md p-2"
                          // value={patientReq.reason}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          required
                        />
                      </div>

                      <div className="border-t pt-2 flex items-center gap-5 justify-between">
                        <div className="flex items-center gap-5 justify-end">
                          <span
                            className="btn btn-default px-3 py-2 hover:shadow-xl border border-gray-300 rounded-md text-black block cursor-pointer duration-300 text-[14px] md:text-[16px]"
                            onClick={() => {
                              setSelectedSlot(null);
                            }}
                          >
                            Back
                          </span>

                          <span
                            className="btn btn-default px-3 py-2 hover:shadow-xl border border-gray-300 rounded-md text-black block cursor-pointer duration-300 text-[14px] md:text-[16px]"
                            onClick={() => {
                              setAppointmentModal({
                                isOpen: false,
                                physicianID: "",
                              });
                            }}
                          >
                            Cancel
                          </span>

                          <span
                            className="btn btn-primary px-5 py-2 bg-[#2A62A4] hover:bg-buttonHover hover:shadow-xl rounded-md text-white duration-300 text-[14px] md:text-[16px] cursor-pointer"
                            onClick={() => {
                              handleBookAndReview();
                            }}
                          >
                            Next
                          </span>
                        </div>
                      </div>
                    </form>
                  )
                ) : (
                  <div>
                    <div className="flex flex-col gap-2">
                      <h1 style={{ fontSize: "20px", margin: "10px 0 0 0" }}>
                        Patient Information
                      </h1>

                      <p>Shamroze Mithani</p>

                      <div>
                        <h4 style={{ fontSize: "20px", margin: "10px 0 0 0" }}>
                          Are you a new patient?
                        </h4>
                        <div className="flex items-center mt-2 isNewPatient">
                          <button
                            className={`flex-1 border border-gray-600 p-3 hover:bg-slate-200 duration-200 ${
                              newPatient === "yes" && "bg-slate-200"
                            }`}
                            onClick={() => setNewPatient("yes")}
                          >
                            Yes
                          </button>
                          <button
                            className={`flex-1 border border-gray-600 p-3 hover:bg-slate-200 duration-200 ${
                              newPatient === "no" && "bg-slate-200"
                            }`}
                            onClick={() => setNewPatient("no")}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="my-5 flex flex-col gap-2">
                      <h1 style={{ fontSize: "20px", margin: "10px 0 0 0" }}>
                        Insurance Information
                      </h1>

                      <InsuranceInput
                        insuranceRef={insuranceRef}
                        insuranceToggle={insuranceToggle}
                        setInsuranceToggle={setInsuranceToggle}
                        insurance={insurance}
                        setInsurance={setInsurance}
                        insurancePhrase={insurancePhrase}
                        setInsurancePhrase={setInsurancePhrase}
                        handleInsuranceChange={handleInsuranceChange}
                        insurancePlan={insurancePlan}
                        setInsurancePlan={setInsurancePlan}
                      />

                      <div>
                        <input
                          type="number"
                          name="member_id"
                          id="member_id"
                          placeholder="Member ID"
                          className="border border-gray-300 rounded-md p-2 w-36"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                        />
                        {!isFocused && errorMemberID && (
                          <p className="text-xs" style={{ color: "red" }}>
                            {errorMemberID}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="my-5 flex flex-col gap-2">
                      {/* term and condition */}
                      <div className="flex items-center gap-2">
                        <input type="checkbox" name="terms" id="terms" />
                        <label htmlFor="terms" className="text-[14px]">
                          I agree to the terms and conditions
                        </label>
                      </div>

                      {/* <div className='flex items-center gap-2' onClick={() => { setOrderToggle(prevState => ({ ...prevState, check: !prevState.check })) }}>
                                            <input
                                                type="checkbox"
                                                name="orderPayment"
                                                id="orderPayment"
                                                checked={orderToggle.check}
                                                onChange={() => { }}
                                            />
                                            <label htmlFor="orderPayment" className='text-[14px]' onClick={() => { setOrderToggle(prevState => ({ ...prevState, check: !prevState.check })) }}>Do you want to Checkout?</label>
                                        </div> */}
                    </div>

                    <div className="border-t pt-2 flex items-center gap-5 justify-end">
                      <button
                        className="btn btn-default px-3 py-2 hover:shadow-xl border border-gray-300 rounded-md text-black duration-300 text-[14px] md:text-[16px]"
                        onClick={() => {
                          setReviewAndBook(false);
                        }}
                      >
                        Back
                      </button>
                      {orderToggle.check ? (
                        <button
                          className="btn btn-primary px-3 py-2 bg-[#2A62A4] hover:bg-buttonHover hover:shadow-xl rounded-md text-white duration-300 text-[14px] md:text-[16px]"
                          onClick={() => {
                            setOrderToggle({ ...orderToggle, isOpen: true });
                          }}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary px-3 py-2 bg-[#2A62A4] hover:bg-buttonHover hover:shadow-xl rounded-md text-white duration-300 text-[14px] md:text-[16px]"
                          onClick={() => {
                            makeAppointment();
                          }}
                        >
                          Make An Appointment
                        </button>
                      )}
                    </div>
                  </div>
                )
              ) : (
                <div>
                  <div className="p-2">
                    <div className="flex items-center justify-between gap-5 border-b border-[#C4C4C4] pb-5">
                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="paymentMethod"
                          id="applePay"
                          className="h-4 w-4"
                          onChange={handlePaymentMethodChange}
                          checked={selectedPaymentMethod === "applePay"}
                        />
                        <label
                          htmlFor="applePay"
                          className="font-[500] text-[18px] xl:text-[20px] leading-[20px] xl:leading-[24px] flex flex-col items-center justify-center gap-2"
                        >
                          <img
                            src={ApplePay}
                            alt="ApplePay"
                            className="w-[56px] h-[83px] object-contain"
                          />
                          <span className="text-xs xl:text-sm">Apple Pay</span>
                        </label>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="paymentMethod"
                          id="googlePay"
                          className="h-4 w-4"
                          onChange={handlePaymentMethodChange}
                          checked={selectedPaymentMethod === "googlePay"}
                        />
                        <label
                          htmlFor="googlePay"
                          className="font-[500] text-[18px] xl:text-[20px] leading-[20px] xl:leading-[24px] flex flex-col items-center justify-center gap-2"
                        >
                          <img
                            src={GooglePay}
                            alt="GooglePay"
                            className="w-[56px] h-[83px] object-contain"
                          />
                          <span className="text-xs xl:text-sm">Google Pay</span>
                        </label>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="radio"
                          name="paymentMethod"
                          id="buyNowPayLater"
                          className="h-4 w-4"
                          onChange={handlePaymentMethodChange}
                          checked={selectedPaymentMethod === "buyNowPayLater"}
                        />
                        <div className="flex flex-col items-center justify-center">
                          <label
                            htmlFor="buyNowPayLater"
                            className="font-[500] text-[18px] xl:text-[20px] leading-[20px] xl:leading-[24px] flex flex-col items-center justify-center gap-2"
                          >
                            <div className="bg-white rounded-lg border border-[#5B6167] w-[65px] lg:w-[65px] lg:h-[35px] flex items-center justify-center p-3">
                              <h1 className="font-[500] text-[16px] leading-[38px] text-[#FFA41C]">
                                Affirm
                              </h1>
                            </div>
                            <span className="text-xs xl:text-sm">
                              Buy Now Pay later
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="pt-10">
                      <div className="flex items-center gap-5">
                        <input
                          type="radio"
                          name="paymentMethod"
                          id="Card"
                          className="h-5 w-5"
                          onChange={handlePaymentMethodChange}
                          checked={selectedPaymentMethod === "Card"}
                        />
                        <label
                          htmlFor="Card"
                          className="font-[500] text-[18px] xl:text-[20px] leading-[20px] xl:leading-[24px]"
                        >
                          Credit/Debit Card
                        </label>
                      </div>

                      <div className="flex flex-col gap-5 mt-5">
                        {selectedPaymentMethod === "Card" && (
                          <div
                            className={`${
                              selectedPaymentMethod === "Card"
                                ? "flex"
                                : "hidden"
                            } flex-col gap-5`}
                          >
                            <div className="flex justify-between items-center gap-2 sm:gap-10">
                              <input
                                type="name"
                                name="cardName"
                                id="cardName"
                                placeholder="Name on Card"
                                className="p-2 lg:p-3 border border-[#B9B9B9] rounded-lg outline-none w-full"
                                required
                              />
                              <input
                                type="text"
                                name="cardNumber"
                                id="cardNumber"
                                placeholder="1234 1234 1234 1234"
                                className="p-2 lg:p-3 border border-[#B9B9B9] rounded-lg outline-none w-full"
                                required
                              />
                            </div>
                            <div className="flex justify-between items-center gap-2 sm:gap-10">
                              <input
                                type="text"
                                name="firstName"
                                id="monthAndYear"
                                placeholder="MM/YY"
                                className="p-2 lg:p-3 border border-[#B9B9B9] rounded-lg outline-none w-full"
                                required
                              />
                              <input
                                type="text"
                                name="cvc"
                                id="cvc"
                                placeholder="CVC"
                                className="p-2 lg:p-3 border border-[#B9B9B9] rounded-lg outline-none w-full"
                                required
                              />
                            </div>
                          </div>
                        )}

                        <p className="font-[500] text-xs lg:text-[16px] leading-[20px] lg:leading-[24px]">
                          Your personal data will be used to process your order,
                          support your experience throughout this website, and
                          for other purposes described in our privacy policy.
                        </p>

                        <div className="flex items-center gap-5 my-1">
                          <input
                            type="checkbox"
                            name="firstTerm"
                            id="firstTerm"
                            className="h-4 lg:h-5 w-4 lg:w-5"
                          />
                          <label
                            htmlFor="firstTerm"
                            className="font-[500] text-xs lg:text-[16px] leading-[16px] lg:leading-[20px]"
                          >
                            I have read and agree to the{" "}
                            <span className="underline text-[#2A62A4]">
                              Terms and Conditions
                            </span>
                          </label>
                        </div>
                        <div className="flex items-center gap-5">
                          <input
                            type="checkbox"
                            name="secondTerm"
                            id="secondTerm"
                            className="h-4 lg:h-5 w-4 lg:w-5"
                          />
                          <label
                            htmlFor="secondTerm"
                            className="font-[500] text-xs lg:text-[16px] leading-[16px] lg:leading-[20px]"
                          >
                            I have read and agree to the second{" "}
                            <span className="underline text-[#2A62A4]">
                              Terms and Conditions
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="border-t pt-2 flex items-center gap-5 justify-end">
                    <button
                      className="px-3 py-2 hover:shadow-xl border border-gray-300 rounded-md text-black duration-300 text-[14px] md:text-[16px]"
                      onClick={() => {
                        setOrderToggle((prevState) => ({
                          ...prevState,
                          isOpen: !prevState.isOpen,
                        }));
                      }}
                    >
                      Back
                    </button>

                    <button
                      className="px-3 py-2 bg-[#2A62A4] hover:bg-buttonHover hover:shadow-xl rounded-md text-white duration-300 text-[14px] md:text-[16px]"
                      onClick={() => {
                        makeAppointment();
                      }}
                    >
                      Submit Order
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AppointmentModal;
