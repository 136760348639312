import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
import Feature from "../../Feature";

export default function FeaturesSection({ sectionTitle, data, handleBookingForm }) {
  return (
    <div className="cs_shape_wrap">
      <div className="cs_shape_1" />
      <div className="container">
        <SectionHeading title={sectionTitle} center />
        <Spacing md="72" lg="50" />
        <div className="cs_random_features">
          {data?.map((item, index) => (
            <div className="cs_random_features_col" key={index}>
              <Feature {...item} />
            </div>
          ))}
        </div>
        <div className="d-lg-block" style={{ textAlign: "center", marginTop: "40px" }}>
          <button
            className="cs_btn cs_style_1"
            onClick={handleBookingForm}
            type="button"
          >
            <span>Book Appointment Now</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </div>
    </div>
  );
}
