export const FormatSlot = (timeString) => {
    const timeParts = timeString.split(':');
    let hours = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    let amPm = 'AM';

    if (hours === 0) {
        hours = 12;
    } else if (hours >= 12) {
        amPm = 'PM';
        if (hours > 12) {
            hours -= 12;
        }
    }

    return `${hours}:${minutes} ${amPm}`;
};